import { ActivityRoles, DataLogStatus } from 'store/app/enums';
import {
  ADD_NOTE_FAILURE,
  ADD_NOTE_REQUEST,
  ADD_NOTE_SUCCESS,
  AWAITING_APPROVAL_FAILURE,
  AWAITING_APPROVAL_REQUEST,
  AWAITING_APPROVAL_SUCCESS,
  CANCEL_RETURN_FAILURE,
  CANCEL_RETURN_REQUEST,
  CANCEL_RETURN_SUCCESS,
  CHANGE_ACTIVITY_ROLE,
  CHANGE_STATUS_AFTER_UPDATE,
  CHECK_ENTITY_STATUS_FAILURE,
  CHECK_ENTITY_STATUS_REQUEST,
  CHECK_ENTITY_STATUS_SUCCESS,
  CLIENT_APPROVE_FAILURE,
  CLIENT_APPROVE_REQUEST,
  CLIENT_APPROVE_SUCCESS,
  EXCEPTIONS_ACTION_FAILURE,
  EXCEPTIONS_ACTION_REQUEST,
  EXCEPTIONS_ACTION_SUCCESS,
  FETCH_ACTIVITY_LOGS_FAILURE,
  FETCH_ACTIVITY_LOGS_REQUEST,
  FETCH_ACTIVITY_LOGS_SUCCESS,
  FETCH_EXCEL_FILE_FAILURE,
  FETCH_EXCEL_FILE_REQUEST,
  FETCH_EXCEL_FILE_SUCCESS,
  FETCH_RUN_DATA_FAILURE,
  FETCH_RUN_DATA_REQUEST,
  FETCH_RUN_DATA_SUCCESS,
  FETCH_VAT_LOGS_FAILURE,
  FETCH_VAT_LOGS_REQUEST,
  FETCH_VAT_LOGS_SUCCESS,
  FETCH_VIEW_RUN_DATA_REQUEST,
  GENERATE_REPORT_FAILURE,
  GENERATE_REPORT_REQUEST,
  GENERATE_REPORT_SUCCESS,
  INTERNALLY_APPROVE_FAILURE,
  INTERNALLY_APPROVE_REQUEST,
  INTERNALLY_APPROVE_SUCCESS,
  LOAD_RECON_FAILURE,
  LOAD_RECON_REQUEST,
  LOAD_RECON_SUCCESS,
  LOAD_REPORT_REQUEST,
  LOCK_RETURN_FAILURE,
  LOCK_RETURN_REQUEST,
  LOCK_RETURN_SUCCESS,
  NOT_APPROVE_FAILURE,
  NOT_APPROVE_REQUEST,
  NOT_APPROVE_SUCCESS,
  RESET_VAT_DATA,
  SAVE_RETURN_FAILURE,
  SAVE_RETURN_REQUEST,
  SAVE_RETURN_SUCCESS,
  SEND_CLIENT_APPROVAL_FAILURE,
  SEND_CLIENT_APPROVAL_REQUEST,
  SEND_CLIENT_APPROVAL_SUCCESS,
  SUBMIT_RETURN_FAILURE,
  SUBMIT_RETURN_REQUEST,
  SUBMIT_RETURN_SUCCESS,
  UPDATE_ACTIVITY_ROLE_FAILURE,
  UPDATE_ACTIVITY_ROLE_REQUEST,
  UPDATE_ACTIVITY_ROLE_SUCCESS,
} from './constants';
import { VatRunData } from './models';
import {
  DataJournal,
  TaxReportSubsectionDataResponse,
} from './models/vatReportData';
import {
  ChangeActivityRoleAction,
  ChangeStatusAfterUpdateAction,
  FetchActivityLogsSuccessAction,
  FetchExcelFileSuccessAction,
  FetchRunDataSuccessAction,
  FetchVatLogsSuccessAction,
  GenerateReportSuccessAction,
  LoadReconSuccessAction,
  SaveReturnSuccessAction,
  VatAction,
  VatState,
} from './types';
import { AccountType, SourceType } from 'store/api/enums';

const initialState: VatState = {
  prepLoading: false,
  loading: false,
  vatReturnLogs: [],
  vatRunData: undefined,
  vatReportData: undefined,
  reconciliation: undefined,
  activityLogs: undefined,
  success: false,
};

export const vatReducer = (
  state = initialState,
  action: VatAction
): VatState => {
  switch (action.type) {
    case SAVE_RETURN_REQUEST:
    case CANCEL_RETURN_REQUEST:
    case UPDATE_ACTIVITY_ROLE_REQUEST:
    case ADD_NOTE_REQUEST:
    case AWAITING_APPROVAL_REQUEST:
    case INTERNALLY_APPROVE_REQUEST:
    case SEND_CLIENT_APPROVAL_REQUEST:
    case CLIENT_APPROVE_REQUEST:
    case NOT_APPROVE_REQUEST:
    case SUBMIT_RETURN_REQUEST:
    case LOCK_RETURN_REQUEST:
    case CHECK_ENTITY_STATUS_REQUEST:
    case FETCH_EXCEL_FILE_REQUEST:
    case EXCEPTIONS_ACTION_REQUEST:
      return handleRequest(state);
    case FETCH_VAT_LOGS_REQUEST:
      return handleFetchVatLogsRequest(state);
    case FETCH_RUN_DATA_REQUEST:
    case FETCH_VIEW_RUN_DATA_REQUEST:
      return handleFetchRunDataRequest(state);
    case GENERATE_REPORT_REQUEST:
    case LOAD_REPORT_REQUEST:
      return handleGenerateReportRequest(state);
    case LOAD_RECON_REQUEST:
      return handleLoadReconRequest(state);
    case FETCH_ACTIVITY_LOGS_REQUEST:
      return handleFetchActivityLogsRequest(state);
    case CANCEL_RETURN_SUCCESS:
    case UPDATE_ACTIVITY_ROLE_SUCCESS:
    case ADD_NOTE_SUCCESS:
    case AWAITING_APPROVAL_SUCCESS:
    case INTERNALLY_APPROVE_SUCCESS:
    case SEND_CLIENT_APPROVAL_SUCCESS:
    case CLIENT_APPROVE_SUCCESS:
    case NOT_APPROVE_SUCCESS:
    case SUBMIT_RETURN_SUCCESS:
    case LOCK_RETURN_SUCCESS:
    case CHECK_ENTITY_STATUS_SUCCESS:
    case EXCEPTIONS_ACTION_SUCCESS:
      return handleSuccess(state);
    case SAVE_RETURN_SUCCESS:
      return handleSaveReturnSuccess(state, action);
    case FETCH_RUN_DATA_SUCCESS:
      return handleFetchRunDataSuccess(state, action);
    case FETCH_VAT_LOGS_SUCCESS:
      return handleFetchVatLogsSuccess(state, action);
    case GENERATE_REPORT_SUCCESS:
      return handleGenerateReportSuccess(state, action);
    case LOAD_RECON_SUCCESS:
      return handleLoadReconSuccess(state, action);
    case FETCH_ACTIVITY_LOGS_SUCCESS:
      return handleFetchActivityLogsSuccess(state, action);
    case FETCH_EXCEL_FILE_SUCCESS:
      return handleFetchExcelFileSuccess(state, action);
    case FETCH_RUN_DATA_FAILURE:
      return handleFetchRunDataFailure(state);
    case GENERATE_REPORT_FAILURE:
      return handleGenerateReportFailure(state);
    case LOAD_RECON_FAILURE:
      return handleLoadReconFailure(state);
    case FETCH_ACTIVITY_LOGS_FAILURE:
      return handleFetchActivityLogsFailure(state);
    case FETCH_VAT_LOGS_FAILURE:
    case SAVE_RETURN_FAILURE:
    case CANCEL_RETURN_FAILURE:
    case UPDATE_ACTIVITY_ROLE_FAILURE:
    case ADD_NOTE_FAILURE:
    case AWAITING_APPROVAL_FAILURE:
    case INTERNALLY_APPROVE_FAILURE:
    case SEND_CLIENT_APPROVAL_FAILURE:
    case CLIENT_APPROVE_FAILURE:
    case NOT_APPROVE_FAILURE:
    case SUBMIT_RETURN_FAILURE:
    case LOCK_RETURN_FAILURE:
    case CHECK_ENTITY_STATUS_FAILURE:
    case FETCH_EXCEL_FILE_FAILURE:
    case EXCEPTIONS_ACTION_FAILURE:
      return handleFailure(state);
    case CHANGE_STATUS_AFTER_UPDATE:
      return handleChangeStatusAfterUpdate(state, action);
    case CHANGE_ACTIVITY_ROLE:
      return handleChangeActivityRole(state, action);
    case RESET_VAT_DATA:
      return handleResetVatData();
    default:
      return state;
  }
};

function handleFetchRunDataRequest(state: VatState): VatState {
  return {
    ...state,
    prepLoading: true,
    vatRunData: undefined,
    //success: false,
  };
}

function handleFetchRunDataSuccess(
  state: VatState,
  action: FetchRunDataSuccessAction
): VatState {
  return {
    ...state,
    prepLoading: false,
    vatRunData: action.payload.vatRunData,
    //success: true,
  };
}

function handleFetchVatLogsRequest(state: VatState): VatState {
  return {
    ...state,
    loading: true,
    vatReturnLogs: [],
  };
}

function handleFetchVatLogsSuccess(
  state: VatState,
  action: FetchVatLogsSuccessAction
): VatState {
  return {
    ...state,
    loading: false,
    vatReturnLogs: action.payload.vatReturnLogs,
  };
}

function handleFetchRunDataFailure(state: VatState): VatState {
  return {
    ...state,
    prepLoading: false,
    vatRunData: undefined,
    //success: false,
  };
}

function handleGenerateReportRequest(state: VatState): VatState {
  return {
    ...state,
    loading: true,
    vatReportData: undefined,
    success: false,
  };
}

function handleGenerateReportSuccess(
  state: VatState,
  action: GenerateReportSuccessAction
): VatState {
  const stateSections = state.vatReportData?.sections
    ? state.vatReportData?.sections
    : [];
  const stateSubsections: TaxReportSubsectionDataResponse[] = state
    .vatReportData?.subsections
    ? state.vatReportData?.subsections
    : [];
  const newSections = action.payload.vatReportData.sections;
  const newSubsections = action.payload.vatReportData.subsections;
  const sections =
    !newSections || newSections.length === 0 ? stateSections : newSections;
  let subsections: TaxReportSubsectionDataResponse[] = [];
  if (newSubsections && newSubsections.length > 0) {
    if (stateSubsections && stateSubsections.length > 0) {
      newSubsections.forEach((ns) => {
        const savedSub = stateSubsections.find((ss) => ss.code === ns.code);
        if (savedSub) {
          const net = savedSub.net + ns.net;
          const tax = savedSub.tax + ns.tax;

          const newSub = { ...ns, net, tax };
          subsections.push(newSub);
        }
      });
    } else {
      subsections = newSubsections;
    }
  } else {
    subsections = stateSubsections;
  }

  const finalSubsections = [...subsections];
  if (action.payload.lastPage) {
    finalSubsections.forEach((fs, idx) => {
      if (fs.booleanExpression && fs.net < 0) {
        subsections[idx].net = 0;
        subsections[idx].tax = 0;
      }
    });
  }

  let journals: DataJournal[] = action.payload.vatReportData.journals;
  let exceptions: DataJournal[] = action.payload.vatReportData.exceptions;
  let summarizedJournals: DataJournal[] = [];

  if (state.vatReportData?.journals) {
    const savedJournals = [...state.vatReportData.journals];
    const newJournals = [...action.payload.vatReportData.journals];
    journals = savedJournals.concat(newJournals);
  }

  if (state.vatReportData?.exceptions) {
    const savedExceptions = [...state.vatReportData.exceptions];
    const newExceptions = [...action.payload.vatReportData.exceptions];
    exceptions = savedExceptions.concat(newExceptions);
  }

  if (action.payload.lastPage) {
    const groupedTransactions: Map<string, DataJournal[]> = new Map<
      string,
      DataJournal[]
    >();
    journals.forEach((j) => {
      const key = j.journalDate + '-' + j.sourceId + '-' + j.sourceType;
      if (groupedTransactions.has(key)) {
        const savedJournals: any = groupedTransactions.get(key);
        const newValue: any = [...savedJournals, j];
        groupedTransactions.set(key, newValue);
      } else {
        const freshVal = [j];
        groupedTransactions.set(key, freshVal);
      }
      j.accountType = AccountType[j.accountType];
      j.sourceType = SourceType[j.sourceType];
    });

    groupedTransactions.forEach((transactions, key) => {
      transactions.sort((a, b) => a.journalNumber - b.journalNumber);
      let lastNumber = 0;
      const groupTotals: Array<{
        journalNumber: number;
        total: number;
        deleted: boolean;
      }> = [];
      let currentTotal = 0;
      let currentNumber = 0;
      let count = transactions.length;
      transactions.forEach((t, idx) => {
        count = count - 1;
        currentNumber = t.journalNumber;
        if (currentNumber === lastNumber) {
          const addAmount = currentTotal + t.netAmount;
          currentTotal = parseFloat(addAmount.toFixed(2));
        } else {
          if (lastNumber !== 0) {
            groupTotals.push({
              journalNumber: lastNumber,
              total: currentTotal,
              deleted: false,
            });
          }
          currentTotal = t.netAmount;
        }
        // if (count === 0) {
        //   groupTotals.set(currentNumber, currentTotal);
        // }
        lastNumber = t.journalNumber;
      });
      currentNumber !== 0 &&
        groupTotals.push({
          journalNumber: currentNumber,
          total: currentTotal,
          deleted: false,
        });

      groupTotals.forEach((gt, idx) => {
        if (!gt.deleted) {
          const otherTotals = groupTotals.filter(
            (gt2) =>
              gt2.total + gt.total === 0 &&
              gt2.journalNumber !== gt.journalNumber &&
              !gt2.deleted
          );

          if (otherTotals.length > 0) {
            transactions = transactions.filter(
              (t) => t.journalNumber !== gt.journalNumber
            );
            groupTotals[idx].deleted = true;

            const rowToRemove = otherTotals[0].journalNumber;
            transactions = transactions.filter(
              (t) => t.journalNumber !== rowToRemove
            );
            groupTotals[
              groupTotals.findIndex((f) => f.journalNumber === rowToRemove)
            ].deleted = true;
          }
        }
      });
      summarizedJournals.push(...transactions);
      // let totalAmount = 0;
      // transactions.forEach((t) => {
      //   const addAmount = totalAmount + t.netAmount;
      //   totalAmount = parseFloat(addAmount.toFixed(2));
      // });
      // if (totalAmount !== 0) {
      //   summarizedJournals.push(...transactions);
      // }
    });

    // journals.sort(function (a, b) {
    //   return (
    //     new Date(b.journalDate).valueOf() - new Date(a.journalDate).valueOf()
    //   );
    // });

    // exceptions.sort(function (a, b) {
    //   return (
    //     new Date(b.journalDate).valueOf() - new Date(a.journalDate).valueOf()
    //   );
    // });

    // summarizedJournals.sort(function (a, b) {
    //   return (
    //     new Date(b.journalDate).valueOf() - new Date(a.journalDate).valueOf()
    //   );
    // });
  }

  return {
    ...state,
    loading: !action.payload.lastPage,
    vatReportData: {
      ...action.payload.vatReportData,
      journals,
      exceptions,
      summarizedJournals,
      sections,
      subsections,
    },
    success: action.payload.lastPage,
  };
}

function handleGenerateReportFailure(state: VatState): VatState {
  return {
    ...state,
    loading: false,
    vatReportData: undefined,
    success: false,
  };
}

function handleSaveReturnSuccess(
  state: VatState,
  action: SaveReturnSuccessAction
): VatState {
  const status = 'SAVED';
  const { dataLogId, users, currentUser, generatedDate } = action.payload;
  const currentStaff = state.vatRunData?.staff;
  const preparerId = currentUser.accountUuid;
  const staff =
    !currentStaff || currentStaff.length === 0
      ? users.filter((u) => AccountType[u.accountType] !== AccountType.CLIENT)
      : currentStaff;
  const currentClients = state.vatRunData?.clients;
  const clients =
    !currentClients || currentClients.length === 0
      ? users.filter((u) => AccountType[u.accountType] === AccountType.CLIENT)
      : currentClients;
  const vatRunData = {
    ...state.vatRunData,
    dataLogId,
    staff,
    clients,
    status,
    preparerId,
    generatedDate,
  } as VatRunData;

  return {
    ...state,
    loading: false,
    //success: true,
    vatRunData,
  };
}

function handleLoadReconRequest(state: VatState): VatState {
  return {
    ...state,
    loading: true,
    reconciliation: undefined,
  };
}

function handleLoadReconSuccess(
  state: VatState,
  action: LoadReconSuccessAction
): VatState {
  return {
    ...state,
    loading: false,
    reconciliation: action.payload.reconMap,
  };
}

function handleLoadReconFailure(state: VatState): VatState {
  return {
    ...state,
    loading: false,
    reconciliation: undefined,
  };
}

function handleFetchActivityLogsRequest(state: VatState): VatState {
  return {
    ...state,
    loading: true,
    activityLogs: undefined,
  };
}

function handleFetchActivityLogsSuccess(
  state: VatState,
  action: FetchActivityLogsSuccessAction
): VatState {
  return {
    ...state,
    loading: false,
    activityLogs: action.payload.activityLogs,
  };
}

function handleFetchActivityLogsFailure(state: VatState): VatState {
  return {
    ...state,
    loading: false,
    activityLogs: undefined,
  };
}

function handleChangeStatusAfterUpdate(
  state: VatState,
  action: ChangeStatusAfterUpdateAction
): VatState {
  let status = '';
  switch (action.payload.status) {
    case DataLogStatus.SAVED:
      status = 'SAVED';
      break;
    case DataLogStatus.NOT_SAVED:
      status = 'NOT_SAVED';
      break;
    case DataLogStatus.AWAITING_APPROVAL:
      status = 'AWAITING_APPROVAL';
      break;
    case DataLogStatus.INTERNAL_APPROVED:
      status = 'INTERNAL_APPROVED';
      break;
    case DataLogStatus.AWAITING_CLIENT_APPROVAL:
      status = 'AWAITING_CLIENT_APPROVAL';
      break;
    case DataLogStatus.CLIENT_APPROVED:
      status = 'CLIENT_APPROVED';
      break;
    case DataLogStatus.SUBMITTED:
      status = 'SUBMITTED';
      break;
    case DataLogStatus.LOCKED:
      status = 'LOCKED';
      break;
  }
  if (state.vatRunData) {
    const vatRunData: VatRunData = {
      ...state.vatRunData,
      status,
    };
    return {
      ...state,
      vatRunData,
    };
  }
  return state;
}

function handleChangeActivityRole(
  state: VatState,
  action: ChangeActivityRoleAction
): VatState {
  if (state.vatRunData) {
    let vatRunData: VatRunData;
    switch (action.payload.activityRole) {
      case ActivityRoles.PREPARER:
        const preparerRow = state.vatRunData.staff?.find(
          (vrd) => vrd.userUuid === action.payload.userId
        );
        const preparer = preparerRow
          ? preparerRow.firstName + ' ' + preparerRow.lastName
          : '';
        vatRunData = {
          ...state.vatRunData,
          preparerId: action.payload.userId,
          preparer,
        };
        break;
      case ActivityRoles.REVIEWER:
        const reviewerRow = state.vatRunData.staff?.find(
          (vrd) => vrd.userUuid === action.payload.userId
        );
        const reviewer = reviewerRow
          ? reviewerRow.firstName + ' ' + reviewerRow.lastName
          : '';
        vatRunData = {
          ...state.vatRunData,
          reviewerId: action.payload.userId,
          reviewer,
        };
        break;
      case ActivityRoles.CLIENT:
        const clientRow = state.vatRunData.clients?.find(
          (vrd) => vrd.userUuid === action.payload.userId
        );
        const client = clientRow
          ? clientRow.firstName + ' ' + clientRow.lastName
          : '';
        vatRunData = {
          ...state.vatRunData,
          clientId: action.payload.userId,
          client,
        };
        break;
      case ActivityRoles.REMOVE_REVIEWER:
        vatRunData = {
          ...state.vatRunData,
          reviewerId: undefined,
          reviewer: undefined,
        };
        break;
      case ActivityRoles.REMOVE_CLIENT:
        vatRunData = {
          ...state.vatRunData,
          clientId: '',
          client: '',
        };
        break;
    }
    return {
      ...state,
      vatRunData,
    };
  }
  return state;
}

// Common
function handleRequest(state: VatState): VatState {
  return {
    ...state,
    loading: true,
    //success: false,
  };
}

function handleSuccess(state: VatState): VatState {
  return {
    ...state,
    loading: false,
    //success: true,
  };
}

function handleFailure(state: VatState): VatState {
  return {
    ...state,
    loading: false,
    //success: false,
  };
}

// Reset
function handleResetVatData(): VatState {
  return initialState;
}

// excel file

function handleFetchExcelFileSuccess(
  state: VatState,
  action: FetchExcelFileSuccessAction
): VatState {
  return {
    ...state,
    loading: false,
    excelFileData: action.payload.data,
  };
}
