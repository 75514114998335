import { call, delay, put, select } from 'redux-saga/effects';
import { fetchMeFailure, fetchMeSuccess } from '../actions';
import { getCurrentUser } from '../api';
import { User } from '../models';
import { ApiError } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { fetchCountriesRequest } from 'store/common/actions';
import { MessageData, ProfilePicture } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { PICTURE_DELAY_TIME } from 'store/app/constants';
import { UserRights } from 'store/user/models';
import { getProfilePictures } from 'store/app/selectors';
import { fetchProfilePictureRequest } from 'store/account/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchMeSaga() {
  try {
    const response: any = yield call(getCurrentUser);

    let user: User = response?.data?.user;
    let err: ApiError | null = checkResponse(response?.responseMessage);
    if (err) throw err;

    let userRights: UserRights[] = [];
    user = {
      ...user,
      userRights,
    };

    yield put(fetchMeSuccess(user));
    yield put(fetchCountriesRequest());
    let pp: ProfilePicture[] = yield select(getProfilePictures);
    if (user && user.hasProfilePicture && !user.profilePicture) {
      const ppIdx = pp.findIndex((p) => p.userUuid === user.userUuid);
      if (ppIdx > -1) {
        const profilePicture = pp[ppIdx].profilePicture;
        user = { ...user, profilePicture };
      } else {
        yield put(fetchProfilePictureRequest(user.userUuid));
        yield delay(PICTURE_DELAY_TIME);
        pp = yield select(getProfilePictures);

        const ppIdx = pp.findIndex((p) => p.userUuid === user.userUuid);
        if (ppIdx > -1) {
          const profilePicture = pp[ppIdx].profilePicture;
          user = { ...user, profilePicture };
        }
      }
    }
    yield put(fetchMeSuccess(user));
    //yield put(push(routes.main));
    // }
  } catch (error) {
    if (error?.status != 401 && error?.status != 403) {
      const message: MessageData = {
        title: 'Server error',
        description: error.message,
        type: MessageStates.ERROR,
      };
      yield put(turnMessageOn(message));
    }
    yield put(fetchMeFailure());
  }
}
