//@ts-nocheck
import { Tag } from 'antd';
import styled from 'styled-components';

export const BoldSpanMidSize = styled.span`
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  padding-right: 6px;
`;

export const BoldSpanLargeSize = styled.span`
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  padding-right: 6px;
`;

export const ColoredBoldSpanLargeSize = styled.span`
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  padding-right: 6px;
  color: #41aaaf;
`;

export const SSOLoginSpan = styled.span`
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #042a2a;
  padding-top: 3px;
`;

export const StrongTitleSpan = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: #042a2a;
  margin-bottom: 10px;
`;

export const StrongTag = styled(Tag)`
  font-family: Inter;
  font-size: ${(props) => (props.smaller ? '10px' : '12px')};
  font-weight: 500;
  line-height: ${(props) => (props.smaller ? '10px' : '12px')};
  text-align: left;
  background: #ffffff;
  padding: ${(props) => (props.smaller ? '4px 6px' : '6px 8px')};
  color: #042a2a;
  border: 1px solid #ffffff;

  &:hover {
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
    text-decoration: ${(props) => (props.onClick ? 'underline' : 'none')};
  }
`;

export const StrongTagDisabled = styled.div`
  font-family: Inter;
  font-size: ${(props) => (props.smaller ? '10px' : '13px')};
  font-weight: 400;
  line-height: ${(props) => (props.smaller ? '10px' : '12px')};
  text-align: left;
  //background: #ffffff;
  padding: ${(props) => (props.smaller ? '4px 6px' : '6px 8px')};
  color: #042a2a;
  //border: 1px solid #ffffff;

  //&:hover {
  //  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  //}
`;
interface ColoredTagProps extends StrongTagProps {
  bgcolor?: string;
  bordercolor?: string;
}
export const ColoredTag = styled(StrongTag)<ColoredTagProps>`
  color: ${(props) => (props.color ? props.color : '#042A2A')} !important;
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : '#ffffff'} !important;
  border-color: ${(props) =>
    props.bordercolor ? props.bordercolor : 'transparent'} !important;
`;

export const GreenSpan = styled.span`
  font-family: Inter;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  line-height: 24px;
  text-align: left;
  color: #41aaaf;
`;

export const DashboardContainer = styled.div`
  width: 24%;
  min-width: 266px;
  height: 165px;
  border-radius: 10px;
  border: 1px solid #dbeae3;
  box-shadow: 0px 0px 10px 0px #0000000d;
  padding: 30px 40px;

  &:hover {
    border: 1px solid #41aaaf;
    box-shadow: 0px 0px 10px 0px #0000000d;
    cursor: pointer;
    .container-icon {
      filter: invert(65%) sepia(21%) saturate(1117%) hue-rotate(134deg)
        brightness(88%) contrast(84%);
    }
  }

  .container-icon {
    float: inline-end;
    width: 24px;
    height: 24px;
  }

  .container-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .container-number {
    font-size: 40px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
    color: #042a2a;
  }

  .container-number-span {
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
    color: #41aaaf;
  }
`;

export const BoldNameSpan = styled.span`
  font-size: 13px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '600')};
  line-height: 18px;
  text-align: left;
  color: #042a2a;
`;

export const GraySpan = styled.span`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #879494;
`;

export const BlackSpan = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #042a2a;
`;

export const CardTitleSpan = styled.span`
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000;
`;

export const CardTitleLargeSpan = styled.span`
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #1d2440;
`;

export const CardDescSpan = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #879494;
`;

export const GrayItalicSpan = styled.span`
  font-family: Inter;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${(props) => (props.color ? props.color : '#042a2a')};
`;
